module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/app/src/intl","languages":["en","ja"],"defaultLanguage":"en"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Santiment","short_name":"Santiment","start_url":"/","background_color":"#14c393","theme_color":"#14c393","display":"minimal-ui","icon":"src/images/logo-SAN.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0a2a41474572c67b1d74dc7ea7471118"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
